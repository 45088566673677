<template>
  <div class="slider-container">
    <full-page ref="fullpage" :options="options" id="fullpage">
      <div
        v-for="(img, index) in imgList"
        :key="index"
        class="section img-box">
        <img v-lazy="img" :src="img" alt="抖工科技">
      </div>
    </full-page>
  </div>
</template>

<script>
export default {
  data() {
    // http://134.175.132.191:8889/pic/dg_1.jpeg

    const imgList = []
    for (let i = 0; i < 16; i++) {
      imgList.push(`http://134.175.132.191:8889/pic/dg_${i + 1}.jpeg`)
    }
    return {
      imgList: imgList,
      options: {
        licenseKey: 'YOUR_KEY_HEERE',
        menu: '#menu',
        anchors: ['page1', 'page2', 'page3'],
        sectionsColor: ['#e14748', '#f4f0e7', '#efebe2', '#e14748', '#f4f0e7', '#e14748', '#f4f0e7', '#efebe2', '#e14748', '#f4f0e7', '#e14748', '#f4f0e7', '#efebe2', '#e14748', '#fffae7', '#fffae7']
      }
    }
  },
}
</script>

<style lang="stylus">
.slider-container
  position fixed
  width 100%
  height 100%
  top 0
  left 0
  z-index 222
  .img-box
    position relative
    width 100%
    img
      display block
      margin 0 auto
      height 100%
    img[lazy=loading], img[lazy=error]
      padding-top 30px
      width 80px
      height auto
      transition all .3s linear 
</style>
